<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import Datepicker from "vue3-datepicker";

/**
 * Daftar Permohonan Component
 */
export default {
  page: {
    title: "Daftar Permohonan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datepicker,
  },
  data() {
    return {
      mps_id: this.$route.query.mps_id,
      status_dashboard: this.$route.query.status,
      title: "Daftar Permohonan",
      loadingTable: true,
      dataTable: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      excelDownload: "",
      start: null,
      end: null,
      status: null,
      search: null,
      filter_status: [
        {
          value: "draft",
          label: "Draft",
        },
        {
          value: "baru",
          label: "Baru",
        },
        {
          value: "menunggu perbaikan",
          label: "Menunggu Perbaikan",
        },
        {
          value: "dibatalkan",
          label: "Dibatalkan",
        },
        {
          value: "proses",
          label: "Proses",
        },
        {
          value: "selesai",
          label: "Selesai",
        },
      ],
    };
  },
  mounted() {
    let self = this;
    // Select List Permohonan
    axios
      .get(process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/list", {
        params: {
          showFor: "Admin",
          mps_id: this.mps_id,
          status_dashboard: this.status_dashboard,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      })
      .then((response) => {
        var response_data = response.data;
        var response_data_fix = response_data.data.permohonan;
        if (response_data.meta.code == 200) {
          self.loadingTable = false;
          self.dataTable = response_data_fix.data;
          self.pagingTable = response_data_fix.links;
          self.showDataToTable = response_data_fix.to;
          self.showDataFromTable = response_data_fix.from;
          self.totalDataTable = response_data_fix.total;
          self.currentTablePage = response_data_fix.current_page;
          self.excelDownload = response_data.data.excel;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch((e) => {
        console.log(e.response);
        this.axiosCatchError = e.response.data.data;
        Swal.close();
      });
  },
  methods: {
    filterPermohonan() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/list", {
          params: {
            showFor: "Admin",
            start: this.start,
            end: this.end,
            status: this.status?.value,
            search: this.search,
            mps_id: this.mps_id,
            status_dashboard: this.status_dashboard,
          },
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.data.permohonan;
          if (response_data.meta.code == 200) {
            self.loadingTable = false;
            self.dataTable = response_data_fix.data;
            self.pagingTable = response_data_fix.links;
            self.showDataToTable = response_data_fix.to;
            self.showDataFromTable = response_data_fix.from;
            self.totalDataTable = response_data_fix.total;
            self.currentTablePage = response_data_fix.current_page;
            self.excelDownload = response_data.data.excel;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((e) => {
          console.log(e.response);
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
    toPage(url) {
      let self = this;
      self.loadingTable = true;
      axios
        .get(url, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.loadingTable = false;
            self.dataTable = response_data_fix.data;
            self.pagingTable = response_data_fix.links;
            self.showDataToTable = response_data_fix.to;
            self.showDataFromTable = response_data_fix.from;
            self.totalDataTable = response_data_fix.total;
            self.currentTablePage = response_data_fix.current_page;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((e) => {
          console.log(e.response);
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
    getExcel() {
      this.getFile(this.excelDownload);
    },
    getFile(files) {
      var win = window.open(process.env.VUE_APP_BACKEND_URL + files, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 my-3">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <div class="col-md">
                        <div class="form-group">
                          <b for="">Tgl Dari</b>
                          <datepicker
                            v-model="start"
                            lang="en"
                            class="form-control bg-white"
                          ></datepicker>
                        </div>
                      </div>
                      <div class="col-md">
                        <div class="form-group">
                          <b for="">Tgl Sampai</b>
                          <datepicker
                            v-model="end"
                            lang="en"
                            class="form-control bg-white"
                          ></datepicker>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <b for="">Status</b>
                          <v-select
                            :options="filter_status"
                            label="label"
                            v-model="status"
                          ></v-select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <b for="">Search</b>
                          <input
                            type="text"
                            class="form-control"
                            v-model="search"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <br />
                    <div
                      class="btn btn-primary btn-sm"
                      v-on:click="filterPermohonan"
                    >
                      <i class="fa fa-filter"></i> Filter
                    </div>
                    <div class="btn btn-success btn-sm" v-on:click="getExcel">
                      <i class="fa fa-download"></i> Excel
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table mb-0 table-bordered table-condensed table-hover"
                  >
                    <thead class="bg-dark text-center text-white">
                      <tr>
                        <th style="width: 50px">No</th>
                        <th>Kode Permohonan</th>
                        <th>Perizinan</th>
                        <th>Permohonan</th>
                        <th>Status</th>
                        <th style="width: 175px">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="6">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr
                        v-for="(row_data, key_data) in dataTable"
                        :key="key_data"
                        v-else
                      >
                        <td class="text-center" style="vertical-align: middle">
                          <div v-if="key_data + 1 == 10">
                            {{ currentTablePage }}0.
                          </div>
                          <div v-else>
                            {{
                              currentTablePage - 1 != 0
                                ? currentTablePage - 1
                                : ""
                            }}{{ key_data + 1 }}.
                          </div>
                        </td>
                        <td class="text-middle-center">
                          <span v-if="row_data.p_kode">{{
                            row_data.p_kode
                          }}</span>
                          <span v-else>Data Tidak Tersedia</span>
                        </td>
                        <td>
                          <table class="table table-sm table-borderless">
                            <tr>
                              <td style="width: 150px">Jenis Perizinan</td>
                              <td style="width: 15px">:</td>
                              <th>
                                <span
                                  v-if="row_data?.perizinan?.perizinanjenis"
                                  >{{
                                    row_data.perizinan.perizinanjenis.mpj_nama
                                  }}</span
                                >
                              </th>
                            </tr>
                            <tr>
                              <td style="width: 150px">Perizinan</td>
                              <td style="width: 15px">:</td>
                              <th>
                                <span v-if="row_data?.perizinan">{{
                                  row_data.perizinan.mp_nama
                                }}</span>
                              </th>
                            </tr>
                            <tr>
                              <td style="width: 150px">Sub Perizinan</td>
                              <td style="width: 15px">:</td>
                              <th>
                                <span v-if="row_data?.perizinansub">{{
                                  row_data.perizinansub.mps_nama
                                }}</span>
                              </th>
                            </tr>
                          </table>
                        </td>
                        <td>
                          <table class="table table-sm table-borderless">
                            <tr>
                              <td style="width: 150px">Nomor Surat</td>
                              <td style="width: 15px">:</td>
                              <th>{{ row_data.p_nomor_surat_permohonan }}</th>
                            </tr>
                            <tr>
                              <td style="width: 150px">Tanggal Surat</td>
                              <td style="width: 15px">:</td>
                              <th>{{ row_data.p_tanggal_surat_permohonan }}</th>
                            </tr>
                            <tr>
                              <td style="width: 150px">Perihal</td>
                              <td style="width: 15px">:</td>
                              <th>{{ row_data.p_perihal }}</th>
                            </tr>
                          </table>
                        </td>
                        <td class="text-middle-center">
                          <badge
                            v-if="row_data.p_status_kategori == 'Draft'"
                            class="badge bg-warning"
                            >{{ row_data.p_status_kategori }}</badge
                          >
                          <badge
                            v-else-if="row_data.p_status_kategori == 'Selesai'"
                            class="badge bg-success"
                            >{{ row_data.p_status_kategori }}</badge
                          >
                          <badge
                            v-else-if="
                              row_data.p_status_kategori == 'Perbaikan'
                            "
                            class="badge bg-warning"
                          >
                            {{ row_data.p_status_kategori }}
                          </badge>
                          <badge
                            v-else-if="row_data.p_status_kategori == 'Ditolak'"
                            class="badge bg-warning"
                            >{{ row_data.p_status_kategori }}</badge
                          >
                          <badge v-else class="badge bg-info">{{
                            row_data.status_permohonan
                          }}</badge>
                        </td>
                        <td class="text-middle-center">
                          <router-link
                            :to="{
                              name: 'admin-detail-permohonan',
                              params: { permohonan_id: row_data.p_id },
                            }"
                            class="btn bg-info text-white w-100"
                          >
                            <i class="fa fa-eye"></i> Detail
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="pt-4">
                  Show {{ showDataFromTable }}-{{ showDataToTable }} From
                  {{ totalDataTable }}
                </div>
              </div>
              <div class="col-md-6">
                <div style="float: right !important">
                  <nav aria-label="pagination" class="mt-3">
                    <ul class="pagination justify-content-start">
                      <li
                        v-for="(page, key_page) in pagingTable"
                        v-bind:class="{ active: page.active }"
                        :key="key_page"
                        class="page-item"
                      >
                        <div
                          v-on:click="toPage(page.url)"
                          class="page-link c-page-link-number"
                        >
                          <div v-html="page.label"></div>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
